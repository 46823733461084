import {
  Box,
  Paper,
  Typography,
} from '@mui/material';

import { SignInButton } from '../components/SignInButton';

export const SignIn = () => {
  return (
    <Paper
      style={{
        width: "327px",
        margin: "auto",
        marginTop: "200px",
        minHeight: "446px",
        borderRadius: "10px",
      }}
    >
      <Box textAlign="center">
    
        <br />
        <Typography>DJUPBLA PLATFORM</Typography>
        <SignInButton />
      </Box>
    </Paper>
  );
};

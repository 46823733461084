const dataFetch = {
  async getOrganisation(instance) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://07abb866-f098-439c-af3a-6446f66cfaea/DJUPBLA.Access"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(`https://api.djupbla.ai/api/admin/organization`, {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    })
      .then((res) => {
        if (res.status === 404) {
          return { name: null };
        } else {
          return res.json();
        }
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return {};
      });
    return response;
  },
  async getAssistants(instance) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://07abb866-f098-439c-af3a-6446f66cfaea/DJUPBLA.Access"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(`https://api.djupbla.ai/api/admin/assistant/get`, {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return [];
      });
    return response;
  },
  async createAssistant(instance, assistantData) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://07abb866-f098-439c-af3a-6446f66cfaea/DJUPBLA.Access"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(`https://api.djupbla.ai/api/admin/assistant/create`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        name: assistantData.name,
        context: assistantData.context,
        language: assistantData.language,
      }),
      headers: new Headers({
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    })
      .then((res) => {
        return {
          status: res.status,
          data: res.status !== 204 ? res.json() : null,
        };
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return null;
      });
    return response;
  },
  async chatWithAssistantDemo(
    instance,
    assistantId,
    message,
    history,
    promptHistory
  ) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://07abb866-f098-439c-af3a-6446f66cfaea/DJUPBLA.Access"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(
      `https://api.djupbla.ai/api/assistant/${assistantId}/chat`,
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          question: message,
          history: history,
          promptHistory: promptHistory,
        }),
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          "x-assistant-id": assistantId,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return {
          status: res.status,
          data: res.status !== 204 ? res.json() : null,
        };
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return null;
      });
    return response;
  },
  async getAssistantDocuments(instance, assistantId) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://07abb866-f098-439c-af3a-6446f66cfaea/DJUPBLA.Access"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(
      `https://api.djupbla.ai/api/admin/assistant/${assistantId}/documents`,
      {
        method: "GET",
        mode: "cors",
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return [];
      });
    return response;
  },
  async deleteAssistantDocument(instance, assistantId, documentId) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://07abb866-f098-439c-af3a-6446f66cfaea/DJUPBLA.Access"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(
      `https://api.djupbla.ai/api/admin/assistant/${assistantId}/delete/${documentId}`,
      {
        method: "DELETE",
        mode: "cors",
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return [];
      });
    return response;
  },
  async getAssistantExternalApi(instance) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://07abb866-f098-439c-af3a-6446f66cfaea/DJUPBLA.Access"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(
      `https://api.djupbla.ai/api/admin/organization/externalapi`,
      {
        method: "GET",
        mode: "cors",
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return null;
      });
    return response;
  },
  async deleteAssistant(instance, assistantId) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://07abb866-f098-439c-af3a-6446f66cfaea/DJUPBLA.Access"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);
    const response = fetch(
      `https://api.djupbla.ai/api/admin/assistant/${assistantId}`,
      {
        method: "DELETE",
        mode: "cors",
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return {
          status: res.status,
          data: res.status !== 204 ? res.json() : null,
        };
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return null;
      });
    return response;
  },
  async uploadDocumentToAssistant(instance, assistantId, files) {
    const user = instance.getActiveAccount();
    const request = {
      scopes: ["api://07abb866-f098-439c-af3a-6446f66cfaea/DJUPBLA.Access"],
      account: user,
    };
    const token = await instance.acquireTokenSilent(request);

    const data = new FormData();
    for (const file of files) {
      data.append("files", file, file.name);
    }
    const response = fetch(
      `https://api.djupbla.ai/api/admin/assistant/${assistantId}/upload`,
      {
        method: "POST",
        mode: "cors",
        body: data,
        headers: new Headers({
          Authorization: "Bearer " + token.accessToken,
          Accept: "application/json",
        }),
      }
    )
      .then((res) => {
        return {
          status: res.status,
          data: res.status !== 201 ? res.json() : null,
        };
      })
      .then((json) => {
        return json;
      })
      .catch((err) => {
        console.error("error: ", err);
        return null;
      });
    return response;
  },
};
export default dataFetch;

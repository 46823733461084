import {
  Avatar,
  Box,
  Button,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import dataFetch from "../services/admin-data-fetcher";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const assistantListStyle = {
  width: "auto",
};

const listItemStyle = {
  width: "auto",
};

export default function AddAssistantDocuments({ assistant, handleClose }) {
  const { instance } = useMsal();
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [assistantDocuments, setAssistantDocuments] = useState([]);
  const [documentsLoaded, setDocumentsLoaded] = useState(false);
  const [uploadNotOk, setUploadNotOk] = useState("none");
  const [uploadOk, setUploadOk] = useState("none");
  const [uploadError, setUploadError] = useState("");

  useEffect(() => {
    if (!documentsLoaded && assistant?.id != null) {
      dataFetch
        .getAssistantDocuments(instance, assistant.id)
        .then((res) => {
          setAssistantDocuments(res);
          setDocumentsLoaded(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [instance, assistant?.id]);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFiles(e.target.files);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles(droppedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const upLoadFiles = () => {
    if (files.length > 0 && assistant?.id) {
      setUploadLoading(true);
      dataFetch
        .uploadDocumentToAssistant(instance, assistant.id, files)
        .then((res) => {
          if (res?.status !== 201) {
            setUploadNotOk("");
            res.data.then((message) => {
              setUploadError(message.split(".")[0]);
            });
          } else {
            setUploadNotOk("none");
            setUploadOk("");
            setUploadError("");
            setDocumentsLoaded(false);
          }
          setUploadLoading(false);
          setFiles([]);
        });
    }
  };
  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            {assistant?.displayName}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Add or remove documents to your assistant, these are used as
            information source for you AI model.
          </Typography>
          <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "2rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label
              htmlFor="file-upload"
              style={{
                border: "4px dotted #ccc",
                display: "inline-block",
                padding: "6px 12px",
                cursor: "pointer",
                textAlign: "center",
                minWidth: 400,
                minHeight: 100,
                marginBottom: 10,
                background: "#f9f9f9",
                borderRadius: "10px",
              }}
            >
              <Typography style={{ marginTop: 18 }}>Select files</Typography>
              <Typography>Or drag and drop files here!</Typography>
            </label>
            {files.length} selected files
            <Typography color="red" style={{ display: uploadNotOk }}>
              Error while uploading file: {uploadError}
            </Typography>
            <Typography color="green" style={{ display: uploadOk }}>
              Successfully uploaded files!
            </Typography>
            <input
              style={{ display: "none" }}
              id="file-upload"
              type="file"
              multiple="multiple"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
            <Button
              variant="contained"
              style={{
                marginBottom: 0,
                marginTop: 10,
                width: "30%",
                background:
                  files.length === 0 || uploadLoading
                    ? "light-gray"
                    : "#C8489F",
              }}
              onClick={upLoadFiles}
              disabled={files.length === 0 || uploadLoading}
            >
              Upload documents
            </Button>
          </div>
          <Typography variant="h5">Documents</Typography>
          <List style={{ overflow: "auto", maxHeight: 300 }}>
            {assistantDocuments.map((document) => {
              if (true) {
                let hello = "hello!";
              }
              return (
                <ListItemButton style={assistantListStyle} key={document.name}>
                  <ListItemAvatar>
                    <Avatar style={{ background: "#C8489F" }}>
                      <WorkIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={document.name}
                    secondary={document.lastModified}
                    style={listItemStyle}
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Box>
      </Modal>
    </div>
  );
}

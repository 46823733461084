import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  CircularProgress,
  Grid,
  Chip,
  Box,
  IconButton,
  InputBase,
  ListItem,
  ListItemIcon,
  Paper,
} from "@mui/material";
import dataFetch from "../services/admin-data-fetcher";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import logoAssistant from "../assets/insai-assistant.png";
import Avatar from "@mui/material/Avatar";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DeleteIcon from "@mui/icons-material/Delete";
import AssessmentIcon from "@mui/icons-material/Assessment";
import KeyIcon from "@mui/icons-material/Key";
import SendIcon from "@mui/icons-material/Send";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CreateAssistantModal from "../components/CreateAssistantModal";
import AddAssistantDocuments from "../components/AddAssistantDocuments";
import DisplayAssistantKeys from "../components/DisplayAssistantKeys";
import DeleteAssistantModal from "../components/DeleteAssistantModal";

const assistantListStyle = {
  width: "auto",
  backgroundColor: "#F7F7F7",
  borderRadius: "8px",
  marginBottom: "8px",
  overflow: "auto",
};

const listItemStyle = {
  width: "auto",
  overflow: "auto",
};

export const HomeAssistant = () => {
  const { instance } = useMsal();
  const [user, setUser] = useState({});
  const [assistants, setAssistants] = useState([]);
  const [assistantsLoaded, setAssistantsLoaded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedAssistant, setSelectedAssistant] = useState(undefined);
  const [openCreateNew, setOpenCreateNew] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [waitingForMessage, setWaitingForMessage] = useState(false);
  const handleOpenCreateNew = () => setOpenCreateNew(true);
  const handleCloseCreateNew = () => setOpenCreateNew(false);
  const [openAddDocuments, setOpenAddDocuments] = useState(false);
  const handleOpenAddDocuments = () => setOpenAddDocuments(true);
  const handleCloseAddDocuments = () => setOpenAddDocuments(false);
  const [openDisplayKeys, setOpenDisplayKeys] = useState(false);
  const handleOpenDisplayKeys = () => setOpenDisplayKeys(true);
  const handleCloseDisplayKeys = () => setOpenDisplayKeys(false);
  const [openDeleteAssistant, setOpenDeleteAssistant] = useState(false);
  const handleOpenDeleteAssistant = () => setOpenDeleteAssistant(true);
  const handleCloseDeleteAssistant = () => setOpenDeleteAssistant(false);
  const [loadingAssistant, setLoadingAssistant] = useState(false);

  const handleListItemClick = (event, index) => {
    setLoadingAssistant(true);
    setSelectedIndex(index);
    setSelectedAssistant(assistants[index]);
    setChatHistory([]);
    setTimeout(() => {
      setLoadingAssistant(false);
    }, "1000");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!waitingForMessage) {
      sendChatMessage();
      setChatMessage("");
    }
  };

  const chatMessageEvent = (event) => {
    setChatMessage(event.target.value);
  };

  const sendChatMessage = () => {
    if (chatMessage !== "" && selectedAssistant?.id) {
      const message = chatMessage;
      setChatMessage("");
      setWaitingForMessage(true);
      dataFetch
        .chatWithAssistantDemo(
          instance,
          selectedAssistant?.id,
          message,
          chatHistory
        )
        .then((chat) => {
          if (chat.status === 200) {
            chat.data.then((assistant) => {
              setChatHistory(assistant.history);
            });
          }
        })
        .finally(() => {
          setWaitingForMessage(false);
        });
    }
  };

  useEffect(() => {
    if (!user) {
      const user = instance.getActiveAccount();
      if (user) {
        setUser(user);
      }
    }
  }, [user, instance]);

  useEffect(() => {
    dataFetch
      .getAssistants(instance)
      .then((res) => {
        setAssistants(res);
        if (res.length > 0) {
          setSelectedAssistant(res[selectedIndex]);
        } else {
          setSelectedAssistant(null);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setAssistantsLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDeleteAssistant, openCreateNew]);

  if (!assistantsLoaded) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Paper
            elevation={6}
            style={{
              minHeight: "200px",
              paddingLeft: 30,
              paddingTop: 30,
              paddingRight: 30,
              textAlign: "center",
            }}
          >
            <CircularProgress style={{ marginTop: 45, color: "#C8489F" }} />
          </Paper>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Paper
              elevation={1}
              style={{
                minHeight: "540px",
                paddingLeft: 30,
                paddingTop: 30,
                paddingRight: 30,
                borderRadius: "8px",
              }}
            >
              <Typography variant="h5">
                <FiberManualRecordIcon
                  fontSize="small"
                  style={{ color: "#14FF2C", marginRight: "10px" }}
                />
                Test your assistant here
              </Typography>
              <Typography paragraph>
                {" "}
                {selectedAssistant?.displayName}
              </Typography>
              <Paper
                component="form"
                sx={{
                  overflowY: "auto",
                  maxHeight: "400px",
                  p: "2px 4px",
                  height: "auto",
                  marginBottom: 2,
                  boxShadow: 0,
                }}
              >
                {loadingAssistant ? (
                  <CircularProgress
                    style={{
                      marginTop: "150px",
                      marginLeft: "50%",
                      color: "#C8489F",
                    }}
                  />
                ) : (
                  <></>
                )}
                {chatHistory?.map((message, index) => {
                  if (message.startsWith("user:")) {
                    return (
                      <Paper
                        key={index}
                        sx={{
                          p: "8px 10px",
                          margin: 1,
                          width: "30%",
                          marginLeft: "auto",
                          bgcolor: "rgba(200, 72, 159, 0.08)",
                          color: "black",
                          marginBottom: 2,
                        }}
                      >
                        {message.replace("user: ", "")}
                      </Paper>
                    );
                  } else {
                    return (
                      <Paper
                        key={index}
                        sx={{
                          p: "8px 10px",
                          margin: 1,
                          width: "80%",
                          marginRight: "auto",
                          bgcolor: "rgba(247, 247, 247)",
                          marginBottom: 2,
                        }}
                      >
                        {message.replace("assistant: ", "")}
                      </Paper>
                    );
                  }
                })}
              </Paper>
            </Paper>
            <Paper
              onSubmit={onSubmit}
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginTop: "20px",
                borderRadius: "8px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Ask me a question"
                inputProps={{ "aria-label": "chat with assistant" }}
                onChange={chatMessageEvent}
                value={chatMessage}
              />
              <IconButton
                color="info"
                sx={{ p: "10px" }}
                aria-label="directions"
                disabled={waitingForMessage}
              >
                {waitingForMessage ? (
                  <CircularProgress
                    style={{
                      maxHeight: "24px",
                      maxWidth: "24px",
                      color: "#C8489F",
                    }}
                  />
                ) : (
                  <SendIcon
                    style={{ color: "#C8489F" }}
                    onClick={sendChatMessage}
                  />
                )}
              </IconButton>
            </Paper>
          </Grid>

          <Grid item xs={5}>
            <Paper
              elevation={1}
              style={{
                minHeight: "200px",
                paddingLeft: 30,
                paddingTop: 30,
                paddingRight: 30,
                borderRadius: "8px",
              }}
            >
              <Grid container>
                <Grid item xs={1}>
                  <Chip
                    style={{ minWidth: 24, minHeight: 24 }}
                    avatar={
                      <Avatar
                        src={logoAssistant}
                        style={{
                          backgroundColor: "#",
                          marginLeft: "18px",
                          minWidth: 24,
                          minHeight: 24,
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={7} style={{ paddingBottom: "20px" }}>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ marginLeft: "10px" }}
                  >
                    Assistants
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{
                      textTransform: "none",
                      float: "right",
                      background: "#C8489F",
                      borderRadius: "8px",
                    }}
                    onClick={handleOpenCreateNew}
                  >
                    Create new
                  </Button>
                </Grid>
              </Grid>
              <List
                component="nav"
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  overflowY: "auto",
                  paddingBottom: "20px",
                  height: "525px",
                }}
              >
                {assistants.map((assistant, index) => {
                  return (
                    <ListItem
                      key={assistant.id}
                      selected={selectedIndex === index}
                      onClick={(event) =>
                        setTimeout(handleListItemClick(event, index), 1000)
                      }
                      style={assistantListStyle}
                    >
                      <Box style={{ width: "100%" }}>
                        <ListItemText
                          primary={assistant?.displayName}
                          secondary={assistant?.context}
                          style={listItemStyle}
                        />
                      </Box>
                      <Box style={{ width: "100%" }}>
                        <ListItemIcon style={{ float: "right" }}>
                          <IconButton>
                            <DeleteIcon onClick={handleOpenDeleteAssistant} />
                          </IconButton>
                        </ListItemIcon>
                        <ListItemIcon style={{ float: "right" }}>
                          <IconButton>
                            <PostAddIcon onClick={handleOpenAddDocuments} />
                          </IconButton>
                        </ListItemIcon>
                        <ListItemIcon style={{ float: "right" }}>
                          <IconButton>
                            <KeyIcon onClick={handleOpenDisplayKeys} />
                          </IconButton>
                        </ListItemIcon>
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              elevation={1}
              style={{
                height: "200px",
                width: "auto",
                marginTop: 40,
                paddingLeft: 30,
                paddingTop: 30,
                paddingRight: 30,
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <AssessmentIcon
                  fontSize="large"
                  style={{ marginRight: "10px" }}
                />
                <Typography variant="h5" sx={{ marginTop: "0px" }}>
                  Statistics
                </Typography>
              </Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
            </Paper>
          </Grid>
        </Grid>
        <CreateAssistantModal
          open={openCreateNew}
          handleClose={handleCloseCreateNew}
        />
        {openAddDocuments && (
          <AddAssistantDocuments
            assistant={selectedAssistant}
            handleClose={handleCloseAddDocuments}
          />
        )}
        <DisplayAssistantKeys
          assistant={selectedAssistant}
          open={openDisplayKeys}
          handleClose={handleCloseDisplayKeys}
        />
        {openDeleteAssistant && (
          <DeleteAssistantModal
            assistant={selectedAssistant}
            handleClose={handleCloseDeleteAssistant}
          />
        )}
      </>
    );
  }
};

import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Paper,
} from "@mui/material";

export const HomeAnalyzer = () => {
  const { instance } = useMsal();
  const [user, setUser] = useState({});

  useEffect(() => {
    if (!user) {
      const user = instance.getActiveAccount();
      if (user) {
        setUser(user);
      }
    }
  }, [user, instance]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Paper
          elevation={6}
          style={{
            minHeight: "200px",
            paddingLeft: 30,
            paddingTop: 30,
            paddingRight: 30,
          }}
        >
          <Typography variant="h4" component="h2">
            Document Analyzer
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

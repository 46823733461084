import {
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import dataFetch from "../services/admin-data-fetcher";
import { useMsal } from "@azure/msal-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

export default function DisplayAssistantKeys({ assistant, open, handleClose }) {
  const { instance } = useMsal();
  const [keysLoaded, setKeysLoaded] = useState(false);
  const [keys, setKeys] = useState(null);
  const [buttonText, setButtonText] = useState("Display Secrets");

  useEffect(() => {
    setKeysLoaded(false);
    setKeys(null);
    setButtonText("Display Secrets");
  }, [open]);

  const handleDisplayKeys = () => {
    if (!keysLoaded && assistant?.id != null && open === true) {
      dataFetch
        .getAssistantExternalApi(instance, assistant.id)
        .then((res) => {
          setKeys(res);
        })
        .finally(() => {
          setKeysLoaded(true);
          setButtonText("Hide secrets");
        });
    } else {
      setKeysLoaded(false);
      setKeys(null);
      setButtonText("Display Secrets");
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            Keys for {assistant?.displayName}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Use keys to interact with assistant externally.
          </Typography>

          <Button
            variant="contained"
            style={{
              marginBottom: 20,
              marginTop: 10,
              width: "30%",
              background: "#C8489F",
            }}
            onClick={handleDisplayKeys}
          >
            {buttonText}
          </Button>
          {keys !== null && (
            <>
              <TextField
                style={{ marginBottom: 20 }}
                disabled
                id="outlined-disabled"
                defaultValue="x-api-key"
                value={keys?.apiKey}
              />
              <TextField
                style={{ marginBottom: 20 }}
                disabled
                id="outlined-disabled"
                defaultValue="x-assistant-key"
                value={assistant?.id}
              />
            </>
          )}
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Example request
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: "Monospace" }}>
                Headers:
                <br />
                x-api-key: {keys !== null ? keys?.apiKey : "{api-key-here}"}
                <br />
                x-assistant-id:{" "}
                {keys !== null ? assistant?.id : "{api-assistant-here}"}
                <br />
                <br />
                POST-body:
                <br />
                {`{
  "question": "string",
  "acl": [
    "string"
  ]
  "history": [
    "string"
  ]
}`}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </div>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, InputAdornment, TextField } from "@mui/material";

import { useRef } from "react";
import dataFetch from "../services/admin-data-fetcher";
import { useMsal } from "@azure/msal-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  "&:focus": {
    outline: "none", // Remove the focus outline
  },
};

const textBoxStyle = {
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
};

const textFieldStyle = {
  marginTop: 40,
};

export default function CreateAssistantModal({ open, handleClose }) {
  const { instance } = useMsal();
  const [validContext, setValidContext] = React.useState(true);
  const [validLanguage, setValidLanguage] = React.useState(true);
  const [hasName, setHasName] = React.useState(false);
  const [hasContext, setHasContext] = React.useState(false);
  const [hasLanguage, setHasLanguage] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const assistantNameRef = useRef();
  const assistantContextRef = useRef();
  const assistantLanguageRef = useRef();

  React.useEffect(() => {
    setValidContext(true);
    setValidLanguage(true);
    setHasName(false);
    setHasContext(false);
    setHasLanguage(false);
    setRequestLoading(false);
  }, [open]);

  const handleAssistantRequest = () => {
    if (hasName && validContext && validLanguage) {
      setRequestLoading(true);
      dataFetch
        .createAssistant(instance, {
          name: assistantNameRef.current.value,
          context: assistantContextRef.current.value,
          language: assistantLanguageRef.current.value,
        })
        .then((res) => {
          if (res.status === 201) {
            handleClose();
          }
        });
    }
  };
  const handleNameValidation = (e) => {
    if (assistantNameRef?.current?.value?.length > 0) {
      setHasName(true);
    } else {
      setHasName(false);
    }
  };
  const handleContextValidation = (e) => {
    const reg = new RegExp("^[A-Za-z\\s]*$");
    setValidContext(reg.test(e.target.value));
    if (assistantContextRef?.current?.value?.length > 0) {
      setHasContext(true);
    } else {
      setValidContext(true);
      setHasContext(false);
    }
  };
  const handleLanguageValidation = (e) => {
    const reg = new RegExp("^[A-Za-z]+$");
    setValidLanguage(reg.test(e.target.value));
    if (assistantLanguageRef?.current?.value?.length > 0) {
      setHasLanguage(true);
    } else {
      setValidLanguage(true);
      setHasLanguage(false);
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            New Assistant
          </Typography>
          <Typography id="modal-modal-description" style={{ marginBottom: 20 }}>
            Create a new assistant and start exploring your custom data.
          </Typography>
          <TextField
            sx={textBoxStyle}
            error={assistantNameRef ? false : !hasName}
            onChange={(e) => handleNameValidation(e)}
            inputRef={assistantNameRef}
            required
            id="outlined-required"
            label="Assistant name"
            style={textFieldStyle}
            fullWidth
            placeholder="Can only contain a-z with no spaces"
            InputProps={{
              startAdornment: <InputAdornment position="start" />,
            }}
          />
          <TextField
            sx={textBoxStyle}
            error={!validContext}
            onChange={(e) => handleContextValidation(e)}
            inputRef={assistantContextRef}
            required
            id="outlined-required"
            label="Contextual information"
            style={textFieldStyle}
            fullWidth
            placeholder="'Helps employees with questions related to work ethics information'"
            InputProps={{
              startAdornment: <InputAdornment position="start" />,
            }}
          />
          <TextField
            sx={textBoxStyle}
            error={!validLanguage}
            onChange={(e) => handleLanguageValidation(e)}
            inputRef={assistantLanguageRef}
            required
            id="outlined-required"
            label="Language"
            style={textFieldStyle}
            fullWidth
            placeholder="'default' makes assistant answer in the same language as question"
            InputProps={{
              startAdornment: <InputAdornment position="start" />,
            }}
          />
          <Button
            variant="contained"
            style={{
              marginBottom: 5,
              marginTop: 50,
              float: "right",
              width: 120,
              textTransform: "none",
              color: "white",
              background:
                !validContext ||
                !validLanguage ||
                requestLoading ||
                !hasName ||
                !hasContext ||
                !hasLanguage
                  ? "LightGray"
                  : "#C8489F",
            }}
            onClick={handleAssistantRequest}
            disabled={
              !validContext ||
              !validLanguage ||
              requestLoading ||
              !hasName ||
              !hasContext ||
              !hasLanguage
            }
          >
            Create
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
